import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

import LetterNote from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/letter-note"
import LetterSidebar from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/letter-sidebar"
import MoodBarometer from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/mood-barometer"
import AudioPlayer from "../../../../../components/audio-player"
import Paragraph from "../../../../../components/paragraph"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/stimmungsbarometer/aufgabe-3" />
      }
    >
      <Seo title="Hölderlin im Stimmungsbarometer" />
      <Stack>
        <Heading as="h2" level={2}>
          Hölderlin im Stimmungsbarometer
        </Heading>
        <Paragraph>
          Ändert sich die Stimmung im Brief an seine Mutter?
        </Paragraph>
        <LetterNote />
        <LetterSidebar
          before={<AudioPlayer src={`kurse/reiseerinnerung/brief-2`} />}
          title="Brief an die Mutter. Lyon den 9. Januar 1802"
          main={
            <>
              <p>
                Sie werden sich wundern, zu dieser Zeit von Lyon aus einen Brief
                von mir zu erhalten. Ich war genöthiget, länger, als ich
                vermuthete in Strasburg zu bleiben, wegen meines Reisepasses,
                und die lange Reise von Strasburg bis hieher wurde durch
                Überschwemmungen und andere unabwendbare Umstände, die mich
                aufhielten, noch länger.
              </p>
              <br />
              <p>
                Es war ein beschwerlicher, und erfahrungsreicher Weg, den ich
                bis hieher machte, aber auch manche reine Freude hab‘ ich
                gefunden. Ich kann es nicht verschweigen, daß ich manchmal an
                euch, ihr Lieben, und auch an den gedachte, von dem mir Muth
                kommt, der mich erhielt bis auf diese Stunde, und ferner mich
                geleiten wird. [...]
              </p>
              <br />
              <p>
                Morgen reis‘ ich nach Bordeaux ab, und werde wohl bald dort
                seyn, da jezt die Wege besser und die Flüsse nicht mehr
                ausgetreten sind...
              </p>
            </>
          }
          sidebar={
            <MoodBarometer
              onChange={({ state }) => {
                setAnswer({
                  courseId: "reiseerinnerung",
                  chapterId: "01-suedfrankreich",
                  taskId: "stimmungsbarometer-2",
                  answer: state,
                })
              }}
            />
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
